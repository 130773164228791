import { useManagersDb } from '../indexedDb/managersDb'
import type {Manager} from '~/models/manager'
import {useLocalstorage} from "~/use/localStorage/store";
import {ManagerApi} from "~/gen/openapi/sblService";
import {useAxiosClient} from "~/use/axios/client";
const { persist } = useLocalstorage()

const state = reactive({
  lastFetchedManagers: persist<number>('lastFetchedManagers', 0),
})

const lastFetchedManagers = computed((): number => state.lastFetchedManagers)


const loadManagers = async (force?: boolean) => {
  const { insertManagers, managersDb } = useManagersDb()
  const { axiosClient } = useAxiosClient()
  const managerApi = new ManagerApi(undefined, '', axiosClient.value)

  if (!force &&
      lastFetchedManagers &&
      lastFetchedManagers.value > Date.now() - 1000 /*ms*/ * 60 /*seconds*/ * 60 /*minutes*/ &&
      await managersDb.getAll().then((m) => m.length)
  ) {
    return
  }

  try {
    const managers: Manager[] = (await managerApi.managerGetManagers()).data
    await insertManagers(managers)
    state.lastFetchedManagers = Date.now()
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useManagersStore = () => ({
  loadManagers
})
