
import { useLocalstorage } from '../localStorage/store'

const { persist } = useLocalstorage()
const state = reactive({
  activeTab: persist<number>('activeTab', 1)
})

const activeTab = computed(() => state.activeTab)

const setActiveTab = (tab: number) => {
  state.activeTab = tab
}

const resetActiveTab = () => {
  state.activeTab = 1
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useContractTypeTabs = () => ({
  activeTab,
  setActiveTab,
  resetActiveTab
})
